@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin appearances {
  animation: fadeInUp 1s forwards;
  opacity: 0;

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@import "app/styles/mixins/breakpoints.scss";

.box {
    align-items: center;
    background-color: #252537;
    border-top-right-radius: 8px;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding-left: 22px;
    padding-right: 14px;

    .name {
        font-size: 14px;
    }

    .btn-container {
        display: flex;
        gap: 11px;
    }
    @include mobile() {
        border-top-left-radius: 8px;
        height: auto;
        padding-bottom: 4px;
        padding-top: 4px;
        width: 100%;
    }
}

@import "../../../../app/styles/mixins/fonts.scss";
@import "../../../../app/styles/mixins/breakpoints.scss";

.item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.info_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  @include mobile() {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.bussines_button_container {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.bussines_button {
  flex: 1;
  letter-spacing: 0.3px;
  padding: 5px 8px;
  text-wrap: nowrap;

  &:first-child {
    flex: 3;
  }
  @include ubuntu-10();

  svg {
    path {
      stroke: currentColor;
    }
  }
}

.info {
  @include ubuntu-medium-14();
}

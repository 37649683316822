@import "app/styles/mixins/fonts.scss";

.widget {
    .info {
        display: flex;
        justify-content: space-between;

        .register {
            @include ubuntu-11();
            color: #5d5d6c;
        }
    }

    .inputs-block {
        display: flex;
        gap: 8px;
        margin-top: 11px;

        & > div {
            flex-grow: 1;
        }
    }
}

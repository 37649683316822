@import "./mixins/fonts.scss";

html,
body {
  height: 100%;
  line-height: 1.3;
  margin: 0;
  max-width: 100vw;
  padding: 0;
}

#root {
  // display: flex;
  // flex-direction: column;
  height: 100%;
}

body {
  background: #121423;
  background-attachment: fixed;
  background-image: url("../../shared/assets/backgrounds/mainbackground.png");
  background-position: bottom;
  background-repeat: no-repeat;
  color: white;
  @include ubuntu-16();
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 1262px;
  padding: 0 15px;
}

* {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
}

// img {
//   height: auto;
//   width: 100%;
// }

button {
  background-color: inherit;
  border: none;
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;
}

input,
textarea {
  background: transparent;
  border: none;
  margin: 0;
  outline: none;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

p {
  margin: 0;
  padding: 0;
}

svg path {
  transition: 0.1s;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.scroll {
  &::-webkit-scrollbar {
    background-color: #252537;
    border-radius: 5px;
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e8edf0;
    border-radius: 5px;
    height: 5px;
    width: 5px;
  }
}

.block {
  background-color: #252537;
  border-radius: 8px;
  padding: 16px;
}

.svg_fill {
  path {
    fill: currentColor;
  }
}

.svg_stroke {
  path {
    stroke: currentColor;
  }
}

.filler-block {
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  height: 200px;
}

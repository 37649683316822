@import "app/styles/mixins/breakpoints.scss";
@import "app/styles/mixins/fonts.scss";

.block {
    display: none;
    padding: 17px 8px 9px;
    @include mobile() {
        display: flex;
    }

    .btn {
        transition: 0.3s;
        position: relative;
        @include ubuntu-medium-12();
        /* stylelint-disable-next-line order/properties-alphabetical-order */
        color: #5d5d6c;

        &:not(:first-of-type) {
            margin-left: 32px;
        }

        &:last-of-type {
            margin-left: auto;
        }

        &::before {
            transition: 0.3s;
            background-color: var(--main-color);
            border-radius: 8px;
            bottom: -9px;
            content: "";
            display: block;
            height: 3px;
            left: 0;
            position: absolute;
            width: 100%;
            opacity: 0;
        }

        &.active {
            @include ubuntu-medium-12();

            &::before {
                opacity: 1;
            }
        }
    }
}

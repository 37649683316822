@import "../../app/styles/mixins/fonts.scss";
@import "../../app/styles/mixins/breakpoints.scss";

.yellow_text {
  @include ubuntu-14();
  color: #feec90;
  text-transform: capitalize;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding: 21px 0;

  @include mobile() {
    display: none;
  }
}

.right_content {
  display: flex;
  gap: 85px;
  letter-spacing: 0.3px;
  text-decoration: none;
}

.link {
  @include ubuntu-14();
  text-decoration: none;
}

.additional {
  display: flex;
  gap: 19px;
}

.catalog_list {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.token_input {
  background-color: #303041;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  color: #ffffff;
  outline: none;
  padding: 10px;
}

.header {
  display: flex;
  gap: 15px;
  margin-bottom: 25px;
}

.image {
  height: 150px;
  width: 150px;
}

.image_content {
  align-items: center;
  display: flex;
  gap: 15px;
}

.upload_button {
  background-color: #303041;
  border: none;
  border-radius: 8px;
  color: inherit;
  color: #ffffff;
  cursor: pointer;
  font: inherit;
  height: 34px;
  outline: none;
  width: 150px;

  &:disabled {
    background: rgba(255, 255, 255, 0.05);
    color: #5d5d6c;
  }
}

.image_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hidden {
  height: 0;
  line-height: 0;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  width: 0;
}

.header_table {
  display: grid;
  grid-template-columns: 305px 91px 450px;
}

.header_table_unauth {
  display: grid;
  grid-template-columns: 258px 91px 334px 437px;
}

.header_item {
  text-align: center;
}

.container {
  width: 100%;
}

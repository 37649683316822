@import "app/styles/mixins/breakpoints.scss";

.box {
    background-color: #252537;
    border-top-left-radius: 8px;
    padding: 8px;

    .input {
        background-color: rgba(255, 255, 255, 0.05);
        height: 32px;

        input {
            padding: 8px 10px;

            &::placeholder {
                color: #5d5d6c;
            }
        }
    }
    @include mobile() {
        background-color: transparent;
    }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 300px;
}

.target_container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.target_item {
  display: flex;
  gap: 15px;
}

.errorText {
  color: red;
}

.target_error_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

@mixin mobile {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin vw1000 {
  @media (max-width: 1000px) {
    @content;
  }
}
@mixin vw1100 {
  @media (max-width: 1100px) {
    @content;
  }
}
@mixin table {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin medium {
  @media (max-width: 900px) {
    @content;
  }
}

@import "../../../app/styles/mixins/fonts.scss";

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 200% 0%;
  }
}
@mixin button {
  @include ubuntu-medium-14();
  align-items: center;
  background: transparent;
  display: flex;
  justify-content: center;
  position: relative;
  transition: 0.3s;
  z-index: 1;

  &:hover {
    color: var(--main-color2);
  }
}

@mixin button-filled {
  @include button();
  border-radius: 8px;
  max-width: 100%;
  padding: 12px;
  width: 100%;

  &:hover {
    color: var(--main-color2);
  }
}

@mixin button-filled-with-before-and-after {
  &::before {
    background: transparent;
    border-radius: inherit;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: 0.3s;
    width: 100%;
    z-index: -1;
  }

  &::after {
    background: transparent;
    border-radius: inherit;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: 0.3s;
    width: 100%;
    z-index: -1;
  }
}
@mixin button-filled-2 {
  @include button-filled();
  background: var(--btn1-bg);
}

@mixin button-filled-3 {
  @include button-filled();
  @include button-filled-with-before-and-after();
  background: transparent;
  color: var(--btn2-color);

  &::before {
    animation: gradient 3s ease infinite;
    background: var(--btn2-bg);
    background-size: 200%;
    opacity: 1;
  }

  &::after {
    background: var(--btn1-bg);
    opacity: 0;
  }

  &:hover {
    color: var(--btn1-color);

    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }
  }
}

@mixin button-filled-4 {
  @include button-filled();
  background: var(--main-color3);
}

@mixin button-filled-5 {
  @include button-filled();
  background-color: var(--main-color6);
  color: #5d5d6c;

  &:hover {
    color: var(--main-color);
  }
}

.btn-1 {
  @include button();
}

.btn-2 {
  @include button-filled-2();
}

.btn-3 {
  @include button-filled-3();
}

.btn-4 {
  @include button-filled-4();
}

.btn-5 {
  @include button-filled-5();
}

.btn-6 {
  @include button-filled-2();
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  gap: 11px;
  height: 100%;
  padding: 0 39px;

  g path {
    stroke: currentColor;
  }
}

.btn-camel {
  @include button-filled();
  background: linear-gradient(90deg, #ad8e6a 0%, #caac88 52.08%, #866a48 100%);
  color: #151727;
}

.btn-dusty_rose {
  @include button-filled();
  background: linear-gradient(272deg, #958078 -3.85%, #f1dccc 59.4%, #b19681 100.65%);
  color: #151727;
}

.btn-exclusive {
  @include button-filled();
  background: linear-gradient(272deg, #e8c251 -3.85%, #fae384 59.4%, #edc35b 100.65%);
  color: #151727;
}

.btn-peach {
  @include button-filled();
  background: linear-gradient(272deg, #b89054 -3.85%, #e7bb79 59.4%, #a87e4e 100.65%);
  color: #151727;
}

.btn-silver {
  @include button-filled();
  background: linear-gradient(272deg, #818181 -3.85%, #d9d9d9 59.4%, #ababab 100.65%);
  color: #151727;
}

.btn-coral {
  @include button-filled();
  background: linear-gradient(260deg, #ab434f -10.43%, #e392aa 76.94%);
  color: #151727;
}

.btn-lemon_chiffon {
  @include button-filled();
  background: linear-gradient(272deg, #e8c251 -3.85%, #fae384 59.4%, #edc35b 100.65%);
  color: #151727;
}

.btn-purple {
  @include button-filled();
  background: linear-gradient(261deg, #842be1 10.77%, #af7eff 89.93%);
  color: #151727;
}

@import "app/styles/mixins/fonts.scss";
@import "app/styles/mixins/breakpoints.scss";

.block {
    background-color: #252537;
    border-radius: 8px;
    display: flex;
    margin-bottom: 8px;
    margin-left: auto;
    max-width: 40%;
    padding: 16px 24px;
    width: max-content;

    .svg-block {
        align-items: center;
        background-color: #313140;
        border-radius: 34px;
        display: flex;
        flex-shrink: 0;
        height: 64px;
        justify-content: center;
        width: 64px;
    }

    .info {
        display: flex;
        margin-left: 20px;

        .desc {
            @include ubuntu-medium-20();
        }

        .time {
            @include ubuntu-11();
            color: #7c7c92;
            margin-left: 5px;
        }
    }
    @include mobile() {
        max-width: 80%;
    }
}

.list {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.item {
  position: relative;
}

.image {
  height: 92px;
  width: 92px;
}

.trash_icon {
  background: rgba(252, 139, 132, 1);
  border-radius: 6px;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
}

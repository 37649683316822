@import "app/styles/mixins/fonts.scss";
@import "app/styles/mixins/breakpoints.scss";

.bar {
    background-color: #252537;
    padding: 8px 14px;
    margin: 8px;

    .statuses-block {
        position: relative;
        border-bottom: 1px solid #42424f;
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        @include ubuntu-14();
        /* stylelint-disable-next-line order/properties-alphabetical-order */
        color: #5d5d6c;

        span {
            transition: 0.3s;
        }

        &::before {
            background-color: var(--main-color);
            border-radius: 8px;
            bottom: 0;
            content: "";
            display: block;
            height: 3px;
            left: 0;
            position: absolute;
            width: 0%;
            transition: 0.3s;
        }

        &.communication {
            &::before {
                width: 25%;
            }

            span:nth-child(1) {
                @include ubuntu-medium-14();
            }
        }

        &.payment {
            &::before {
                width: 50%;
            }

            span:nth-child(1),
            span:nth-child(2) {
                @include ubuntu-medium-14();
            }
        }

        &.meeting {
            &::before {
                width: 75%;
            }

            span:nth-child(1),
            span:nth-child(2),
            span:nth-child(3) {
                @include ubuntu-medium-14();
            }
        }

        &.ending {
            &::before {
                width: 100%;
            }

            span:nth-child(1),
            span:nth-child(2),
            span:nth-child(3),
            span:nth-child(4) {
                @include ubuntu-medium-14();
            }
        }
    }

    @include mobile() {
        margin-left: 0;
        margin-right: 0;

        .statuses-block {
            &.communication,
            &.payment,
            &.meeting,
            &.ending {
                span:nth-child(1),
                span:nth-child(2),
                span:nth-child(3),
                span:nth-child(4) {
                    font-size: 12px;
                }
            }
        }
    }
}

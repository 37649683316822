.cell {
  align-items: center;
  border: 1px solid #e3e6ec;
  color: #5a5c66;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  line-height: normal;
  padding: 5px;
  text-align: center;
  word-wrap: break-word;
}

.inputName {
  color: #5a5c66;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  width: 100%;
  word-wrap: break-word;
}

.textarea {
  color: #5a5c66;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  resize: none;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  word-wrap: break-word;
}

.inputText {
  color: #5a5c66;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  min-width: 124px;
  text-align: center;
  width: 100%;
  word-wrap: break-word;
}

@import "../../../../app/styles/mixins/fonts.scss";

.container {
  background-color: rgba(37, 37, 55, 1);
  border-radius: 16px;
  display: flex;
}

.main_informations {
  padding: 17px 15px 16px 23px;
}

.name_text {
  @include ubuntu-medium-20();
}

.badge {
  @include ubuntu-14();
  color: rgba(24, 24, 42, 1);
  margin-left: 17px;
}

.location {
  margin-top: 7px;
}

.location_text {
  @include ubuntu-14();
}

.date {
  margin-top: 23px;
}

.date_text {
  @include ubuntu-10();
  color: rgba(93, 93, 108, 1);
}

.button {
  box-shadow: 0 0 56px 0 rgba(230, 192, 78, 0.43);
  margin-top: 16px;
}

@import "app/styles/mixins/fonts.scss";
@import "app/styles/mixins/breakpoints.scss";

.card {
    background-color: #252537;
    border: 2px solid #5d5d6c;
    border-radius: 16px;
    padding: 24px 32px;

    .badge {
        font-size: 11px;
    }

    .info {
        margin-top: 21px;

        .title {
            @include montserrat-bold-32();
        }

        .desc {
            @include ubuntu-medium-14();
        }
    }

    .control {
        align-items: center;
        display: flex;
        gap: 8px;
        margin-top: 34px;

        .amount {
            align-items: center;
            background-color: rgba(255, 255, 255, 0.05);
            border-radius: 8px;
            display: flex;
            flex: 2;
            justify-content: space-between;
            padding: 12px 16px;

            .label {
                color: #6b6b7d;
                font-size: 11px;
            }

            .sum {
                @include ubuntu-medium-14();
            }
        }

        .btn {
            flex: 1;

            .svg {
                margin-left: 7px;

                g,
                path {
                    stroke: #ffffff;
                }
            }

            &:disabled:hover {
                color: inherit;
                cursor: auto;
            }
        }
    }

    &.activated {
        .info {
            .title,
            .desc {
                color: #5d5d6c;
            }
        }
    }

    &.available {
        border-color: #e6c04e;

        .info {
            .title,
            .desc {
                background: linear-gradient(272deg, #e8c251 -3.85%, #fae384 59.4%, #edc35b 100.65%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                text-shadow: 0 0 65px #feec90;
            }
        }
    }
}

@import "app/styles/mixins/fonts.scss";

.test {
  text-align: center;
}

.head {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px;
  padding: 12px 48px;

  .title {
    @include ubuntu-medium-20();
  }

  .btns {
    display: flex;
    gap: 8px;

    & > * {
      width: max-content;
    }
  }
}

.button {
  padding: 12px 25px;
}

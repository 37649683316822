@import "../../../../../../app/styles/mixins/fonts.scss";

.transaction_list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.transaction_item {
  display: flex;
}

.transaction_date {
  @include ubuntu-14();
  color: rgba(93, 93, 108, 1);
  display: flex;

  &:nth-child(1) {
    margin-right: 5px;
  }
}

.transaction_sum {
  @include ubuntu-14();
  color: rgba(254, 236, 144, 1);
  margin-left: auto;
}

@import "../../../app/styles/mixins/fonts.scss";
@import "../../../app/styles/mixins/utility.scss";

.banner {
  align-items: center;
  background: rgba(37, 37, 55, 1);
  border-radius: 16px;
  display: flex;
  gap: 54px;
  margin-bottom: 25px;
  padding: 12px 50px;

  .title {
    @include ubuntu-medium-20();
  }

  .input_container {
    position: relative;
    width: 100%;
  }

  .search_icon {
    @include vertical-center();
    display: flex;
    right: 20px;
  }

  .input {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    color: #ffffff;
    padding: 12px 45px 12px 24px;
    width: 100%;

    &::placeholder {
      @include ubuntu-14();
      color: rgba(107, 107, 125, 1);
    }
  }
}

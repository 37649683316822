@import "app/styles/mixins/breakpoints.scss";

.item-container {
    position: relative;
    z-index: 3;

    .chat-item {
        align-items: center;
        background-color: rgba(255, 255, 255, 0);
        cursor: pointer;
        display: flex;
        flex-shrink: 0;
        height: 62px;
        padding: 6px 9px;
        position: relative;
        transition: 0.3s;
        z-index: 2;

        &.active,
        &:hover {
            background-color: rgba(255, 255, 255, 0.05);

            // background-color: #7C7C92;
        }

        .photo {
            img {
                border-radius: 20px;
                height: 42px;
                width: 42px;
            }
        }

        .name-block {
            margin-left: 13px;
            overflow: hidden;

            .name {
                align-items: center;
                display: flex;
                font-size: 14px;
            }

            .message {
                color: #7c7c92;
                font-size: 11px;
                margin-top: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .icons-block {
            display: flex;
            margin-left: auto;

            .icons {
                display: flex;
                flex-direction: column;
                gap: 4px;
                margin-left: 26px;

                .attach {
                    transition: 0.3s;

                    &.attached
                // ,&:hover
                    {
                        g {
                            stroke: var(--main-color2);
                        }
                    }
                }

                .favourite {
                    transition: 0.3s;

                    g {
                        path {
                            stroke: #42424f;
                        }
                    }

                    &.fav
                // ,&:hover
                    {
                        g {
                            fill: #e6c04e;

                            path {
                                stroke: #e6c04e;
                            }
                        }
                    }
                }
            }

            .info {
                align-items: flex-end;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                margin-left: 7px;

                .time {
                    color: #7c7c92;
                    font-size: 11px;
                }

                .unread-messages-count {
                    background-color: #e6c04e;
                    border-radius: 16px;
                    color: #151727;
                    font-size: 8px;
                    margin-top: 7px;
                    padding: 2px 6px;
                }
            }
        }

        @include mobile() {
            &,
            &.active,
            &:hover {
                background-color: #151727;
            }
        }
    }

    .mob-btns-container {
        display: none;
        @include mobile() {
            bottom: 0;
            display: flex;
            opacity: 1;
            position: absolute;
            right: 0;
            top: 0;
            transition: 0.3s;
            width: 144px;
            z-index: 1;

            .pin,
            .delete {
                align-items: center;
                display: flex;
                flex: 1;
                flex-direction: column;
                font-size: 10px;
                gap: 5px;
                justify-content: center;
            }

            .pin {
                background-color: #42424f;
            }

            .delete {
                background-color: #ff5f5f;
            }

            &.hide {
                opacity: 0;
            }
        }
    }
}

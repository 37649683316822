@mixin ubuntu-text {
  color: var(--main-color);
  font-family: Ubuntu, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@mixin ubuntu-8 {
  @include ubuntu-text();
  font-size: 8px;
}
@mixin ubuntu-medium-8 {
  @include ubuntu-8();
  font-weight: 500;
}
@mixin ubuntu-10 {
  @include ubuntu-text();
  font-size: 10px;
}
@mixin ubuntu-medium-10 {
  @include ubuntu-10();
  font-weight: 500;
}
@mixin ubuntu-11 {
  @include ubuntu-text();
  font-size: 11px;
}

@mixin ubuntu-medium-11 {
  @include ubuntu-11();
  font-weight: 500;
}
@mixin ubuntu-bold-11 {
  @include ubuntu-11();
  font-weight: 700;
}
@mixin ubuntu-12 {
  @include ubuntu-text();
  font-size: 12px;
}
@mixin ubuntu-medium-12 {
  @include ubuntu-12();
  font-weight: 500;
}
@mixin ubuntu-14 {
  @include ubuntu-text();
  font-size: 14px;
}

@mixin ubuntu-medium-14 {
  @include ubuntu-14();
  font-weight: 500;
}

@mixin ubuntu-bold-14 {
  @include ubuntu-14();
  font-weight: 700;
}

@mixin ubuntu-16 {
  @include ubuntu-text();
  font-size: 16px;
}
@mixin ubuntu-medium-16 {
  @include ubuntu-16();
  font-weight: 500;
}
@mixin ubuntu-20 {
  @include ubuntu-text();
  font-size: 20px;
}
@mixin ubuntu-medium-20 {
  @include ubuntu-20();
  font-weight: 500;
}
@mixin ubuntu-28 {
  @include ubuntu-text();
  font-size: 28px;
}
@mixin ubuntu-bold-28 {
  @include ubuntu-28();
  font-weight: 700;
}

@mixin ubuntu-30 {
  @include ubuntu-text();
  font-size: 30px;
}

@mixin ubuntu-bold-30 {
  @include ubuntu-30();
  font-weight: 700;
}
@mixin ubuntu-48 {
  @include ubuntu-text();
  font-size: 48px;
}

@mixin ubuntu-bold-48 {
  @include ubuntu-48();
  font-weight: 700;
}

@mixin montserrat-bold {
  color: var(--main-color);
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
@mixin montserrat-bold-14 {
  @include montserrat-bold();
  font-size: 14px;
}

@mixin montserrat-bold-20 {
  @include montserrat-bold();
  font-size: 20px;
}

@mixin montserrat-bold-28 {
  @include montserrat-bold();
  font-size: 28px;
}

@mixin montserrat-bold-32 {
  @include montserrat-bold();
  font-size: 32px;
}

@mixin montserrat-bold-40 {
  @include montserrat-bold();
  font-size: 40px;
}

@import "../../../../app/styles/mixins/fonts.scss";

.block {
  background: #252537 ();
  border-radius: 6px;
  margin-top: 8px;
  padding: 12px 12px 16px;

  .info {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    @include ubuntu-14();
  }
    .info2 {
        color: rgba(255, 255, 255, 0.5);
        display: flex;
        font-size: 9px;
        justify-content: space-between;
        margin-top: 4px;
    }

    .btn {
        font-weight: 400;
    }

}

.box {
    display: flex;
    flex-direction: column;
    height: 100%;

    // height: 680px;
    max-height: 680px;

    // height: 680px;

    // padding: 8px;
    &.select-chat {
        align-items: center;
        justify-content: center;
    }
}

.wrapper {
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.5s;
  z-index: 9999;

  .modal {
    background-color: #252537;
    border-radius: 16px;
    max-width: calc(100% - 30px);
    padding: 20px 26px;
    position: relative;
    z-index: 2;

    & > p:first-child {
      max-width: 90%;
    }

    .close {
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 24px;

      path {
        stroke: #ffffff;
        transition: 0.3s;
      }

      &:hover {
        path {
          stroke: var(--main-color2);
        }
      }
    }
  }
}

.show {
  opacity: 1;
  transition: 0.5s;
}

.hide {
  opacity: 0;
  transition: 0.5s;
}

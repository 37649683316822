@import "../../../../../../app/styles/mixins/fonts.scss";

.list {
  display: flex;
  gap: 21px;
  margin-top: 5px;
  position: relative;

  &::before {
    background-color: rgba(66, 66, 79, 1);
    content: "";
    height: 2px;
    position: absolute;
    top: 25px;
    width: 100%;
  }
}

/// item потом нужно будет переписать под бэк

.item {
  @include ubuntu-medium-10();
  color: rgba(93, 93, 108, 1);
  display: block;

  &:nth-child(1) {
    color: #ffffff;
  }
}

.bar {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  height: 3px;
  margin-top: 11px;
  position: relative;
}

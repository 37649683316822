.justify-space-between {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.flex {
    display: flex;
}

.gap5 {
    gap: 5px;
}

@import "../../app/styles/mixins/fonts.scss";
@import "../../app/styles/mixins/breakpoints.scss";

.item_link {
  @include ubuntu-14();
  text-decoration: none;
}

.nav {
  display: none;

  @include mobile() {
    bottom: 0;
    display: block;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 10;
  }
}

.active_item_link {
  align-items: center;
  color: #feec90;
  display: flex;
  height: max-content;

  svg {
    path {
      stroke: #feec90;
    }
  }
}

.list {
  background-color: #151727;
  display: flex;
  justify-content: space-between;
  padding: 17px 15px 34px;
}

.item {
  position: relative;
}

.badge {
  @include ubuntu-8();
  background-color: #e6c04e;
  border-radius: 17px;
  color: #151727;
  padding: 2px 3px 1px 4px;
  position: absolute;
  right: -5px;
  top: -4px;
}

.mt-a {
    margin-top: auto;
}

.mt15 {
    margin-top: 15px;
}

.mr-12 {
    margin-right: 12px;
}

.mr-15 {
    margin-right: 15px;
}

@import "app/styles/mixins/breakpoints.scss";

.btn {
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    display: flex;
    height: 62px;
    padding: 6px 9px;
    transition: 0.3s;

    &.active,
    &:hover {
        background-color: rgba(255, 255, 255, 0.05);
    }

    .svg-block {
        align-items: center;
        background-color: #5d5d6c;
        border-radius: 20px;
        display: flex;
        height: 42px;
        justify-content: center;
        width: 42px;

        svg path {
            fill: transparent;
            transition: 0.3s;
        }
    }

    &.active .svg-block svg path {
        fill: var(--main-color);
    }

    .title-block {
        margin-left: 13px;
        overflow: hidden;

        .title {
            align-items: center;
            display: flex;
            font-size: 14px;
        }

        .desc {
            color: #7c7c92;
            font-size: 11px;
            margin-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    @include mobile() {
        display: none;
    }
}

@import "app/styles/mixins/breakpoints.scss";

.btn {
    align-items: center;
    background-color: #303041;
    color: #5d5d6c;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    height: 48px;
    margin-top: auto;
    padding: 8px 10px;
    transition: 0.3s;

    .svg-container {
        background-color: #42424f;
        border-radius: 14px;
        display: flex;
        margin-right: 10px;
        padding: 8px;

        svg {
            transition: 0.3s;
        }
    }

    &:hover {
        color: var(--main-color);

        svg {
            path {
                stroke: var(--main-color);
            }
        }
    }
    @include mobile() {
        display: none;
    }
}

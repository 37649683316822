.checkbox_item {
  display: flex;
  gap: 25px;
}

.checkbox_list {
  background: #313140;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 200px;
  padding: 10px;
}

.name {
  text-wrap: nowrap;
}

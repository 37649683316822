@import "../../../../app/styles/mixins/fonts.scss";

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.item {
  position: relative;
}

.image {
  height: 100%;
}

.delete {
  position: absolute;
  right: 9px;
  top: 9px;
}

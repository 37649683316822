@import "app/styles/mixins/fonts.scss";
@import "app/styles/mixins/breakpoints.scss";

.window {
    // display: flex;
    // flex-direction: column;
    flex-grow: 0;
    flex-shrink: 1;
    justify-content: center;
    max-height: calc(680px - 48px - 48px - 16px);
    overflow-y: auto;
    padding: 8px 15px;

    &.manager {
        max-height: calc(680px - 48px);
    }

    .intro {
        margin-bottom: 20px;

        .title {
            @include ubuntu-medium-14();
            color: #feec90;
            text-align: center;
        }

        .desc {
            @include ubuntu-14();
            color: #5d5d6c;
            margin-top: 5px;
            text-align: center;
        }
    }

    .date {
        @include ubuntu-14();
        color: #5d5d6c;
        margin-bottom: 13px;
        text-align: center;
    }

    .select-chat-block {
        @include ubuntu-12();
    }
    @include mobile() {
        padding-left: 6px;
        padding-right: 6px;

        .intro {
            .title,
            .desc {
                font-size: 12px;
            }
        }
    }
}

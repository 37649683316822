@import "../../app/styles/mixins/fonts.scss";

.banner {
  align-items: center;
  background-color: rgba(37, 37, 55, 1);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  padding: 20px 48px 21px 49px;
}

.button {
  gap: 14px;
  max-width: 192px ();

  g path {
    stroke: currentColor;
  }
}

.title {
  @include ubuntu-medium-20();
}

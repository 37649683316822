@import "../../../../app/styles/mixins/fonts.scss";

.card {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    min-height: 200px;

    .wrapper {
        align-items: flex-start;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        padding: 13px;

        .name {
            cursor: pointer;
            margin-top: 6px;
            @include ubuntu-medium-20();
        }

        .country {
            @include ubuntu-14();
            margin-top: 4px;
        }
    }

    // .img {
    //     border-radius: 6px;
    //     height: 100%;
    //     max-width: 100%;
    // }
}

.input {
  max-width: 200px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.text_textarea {
  background: #313140;
  border-radius: 6px;
  color: #ffffff;
  max-width: 200px;
  min-height: 100px;
  padding: 6px 14px;
  padding-top: 17px;
  resize: none;

  &::placeholder {
    color: #5d5d6c;
  }
}

.tip {
  color: #5d5d6c;
  font-size: 14px;
  left: 13px;
  pointer-events: none;
  position: absolute;
  top: 12px;
  transition: 0.3s;
}

.tip_active {
  font-size: 10px;
  top: 6px;
}

.textarea_container {
  position: relative;
}

.checkbox_item {
  display: flex;
  gap: 25px;
}

.checkbox_list {
  background: #313140;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 200px;
  padding: 10px;
}

.create_notification {
  align-items: flex-end;
  display: flex;
  gap: 25px;
}

.button {
  height: max-content;
  width: 200px;
}

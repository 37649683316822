@import "app/styles/mixins/breakpoints.scss";

.list {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 15px;

    .btn {
        align-items: center;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        height: 200px;
        justify-content: center;
        transition: 0.3s;

        &:hover {
            color: var(--main-color2);
        }
    }
    @include table() {
        grid-template-columns: repeat(3, 1fr);
    }

    @include medium() {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 705px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

@import "app/styles/mixins/breakpoints.scss";

.widget {
    display: grid;
    gap: 1px;

    // grid-template-areas: "left left left right right right right right right";
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: [row1-start] 48px;

    .left {
        grid-column: 1 / 4;

        // grid-area: left;
    }

    .right {
        // grid-area: right;
        grid-column: 4 / 12;
    }
    @include mobile() {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;

        .left {
            // grid-column: 1 / 12;
            transition: 0.3s;
            z-index: 1;

            &.hide {
                // display: none;
                opacity: 0;
            }
        }

        .right {
            // grid-column: 1 / 12;
            left: 100%;
            max-width: 100%;
            opacity: 0;
            position: absolute;

            // position: relative;
            top: 0;
            transition: 0.3s;
            z-index: 2;

            &.top {
                height: auto;
                top: 0;
                width: 100%;
            }

            &.bottom {
                bottom: 0;
                top: 29px;
                width: 100%;
            }

            &.show {
                // grid-column: 1 / 12;
                left: 0;
                opacity: 1;
            }
        }
    }
}

@import "../../../app/styles/mixins/fonts.scss";

.title {
  @include ubuntu-medium-20();
  width: 223px;
}

.subtitle {
  @include ubuntu-14();
  color: rgba(255, 255, 255, 0.5);
  margin-top: 4px;
}

.button_container {
  display: grid;
  gap: 8px;
  grid-template-columns: 111px 175px;
  margin-top: 18px;
}

@import "app/styles/mixins/breakpoints.scss";

.rating-block {
    background-color: #252537;
    border-radius: 8px;
    font-size: 14px;
    margin-bottom: 8px;
    max-width: 50%;
    padding: 16px 24px;
    width: max-content;

    .title {
        align-items: center;
        display: flex;

        svg {
            margin-right: 8px;
        }
    }

    .desc {
        color: #7c7c92;
        margin-top: 8px;
    }

    .girls {
        display: flex;
        gap: 8px;
        margin-top: 8px;

        svg {
            cursor: pointer;

            path {
                transition: 0.3s;
            }

            &:hover {
                path {
                    fill: #e6c04e;
                }
            }
        }

        &.girls1 {
            svg:nth-child(1) {
                path {
                    fill: #e6c04e;
                }
            }
        }

        &.girls2 {
            svg:nth-child(1),
            svg:nth-child(2) {
                path {
                    fill: #e6c04e;
                }
            }
        }

        &.girls3 {
            svg:nth-child(1),
            svg:nth-child(2),
            svg:nth-child(3) {
                path {
                    fill: #e6c04e;
                }
            }
        }

        &.girls4 {
            svg:nth-child(1),
            svg:nth-child(2),
            svg:nth-child(3),
            svg:nth-child(4) {
                path {
                    fill: #e6c04e;
                }
            }
        }

        &.girls5 {
            svg:nth-child(1),
            svg:nth-child(2),
            svg:nth-child(3),
            svg:nth-child(4),
            svg:nth-child(5) {
                path {
                    fill: #e6c04e;
                }
            }
        }
    }
    @include mobile() {
        max-width: 80%;
    }
}

@import "../../../app/styles/mixins/fonts.scss";

.modal {
    width: 344px;
}

.title {
    @include ubuntu-medium-20();
}

.infoblock {
    align-items: flex-end;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    margin-top: 15px;

    .infoleft {
        color: rgba(255, 255, 255, 0.5);
        max-width: 70%;
    }

    .inforight {
        color: #feec90;
    }
}

.buttonsblock {
    display: flex;
    gap: 8px;
    margin-top: 22px;

    .no {
        flex-basis: calc(100% / 3);
    }

    // & > * {
    //     width: max-content;
    // }
}

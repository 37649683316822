@import "app/styles/mixins/breakpoints.scss";

.block {
    display: grid;
    gap: 16px 23px;
    grid-template-columns: repeat(2, 1fr);

    @include vw1100() {
        grid-template-columns: 1fr;
    }
}

@import "app/styles/mixins/breakpoints.scss";
@import "app/styles/mixins/fonts.scss";

.box {
    background-color: #252537;
    border-radius: 8px;
    font-size: 14px;
    margin-bottom: 8px;
    max-width: 50%;
    padding: 16px 24px;
    width: max-content;

    .title {
        align-items: center;
        display: flex;
        @include ubuntu-medium-14();

        svg {
            margin-right: 8px;
        }
    }

    .desc {
        color: #7c7c92;
        margin-top: 8px;
    }
    @include mobile() {
        max-width: 80%;
    }
}

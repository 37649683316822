@import "../../../app/styles/mixins/fonts.scss";

.icon_container {
  align-items: center;
  background-color: rgba(49, 49, 64, 1);
  border-radius: 34px;
  display: flex;
  height: 54px;
  justify-content: center;
  width: 54px;
}

.container {
  align-items: center;
  display: flex;
  gap: 16px;
}

.price {
  @include ubuntu-medium-20();
}

.subtitle {
  @include ubuntu-14();
  color: #5d5d6c;
}

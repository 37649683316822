@import "../../../app/styles/mixins/breakpoints.scss";

.list {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 15px;

  @include table() {
    grid-template-columns: repeat(3, 1fr);
  }

  @include medium() {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 705px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

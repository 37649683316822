@import "app/styles/mixins/fonts.scss";
@import "app/styles/mixins/breakpoints.scss";
@import "app/styles/mixins/utility.scss";

.text_container {
  margin-bottom: 15px;
}

.is_favorite {
  path {
    stroke: #e6c04e;
  }
}

.item {
  @include appearances();
  background: #252537;
  border: 2px solid #776c68;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  padding: 25px 48px 24px 46px;

  @include mobile() {
    padding: 17px 21px;
  }

  @media (max-width: 1036px) {
    padding: 17px 21px;
  }
}

.image {
  border: 1.5px solid #151727;
  border-radius: 40px;
  margin-right: -6px;
  z-index: 3;
}

.last_update {
  @include ubuntu-11();
  height: max-content;
}

.icon_button {
  padding: 8px;
  width: auto;

  &:hover {
    svg {
      path {
        stroke: currentColor;
      }
    }
  }
}

.title {
  @include montserrat-bold-40();
  background: linear-gradient(272deg, #958078 -3.85%, #f1dccc 59.4%, #b19681 100.65%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 65px #cdb5a2;
  text-transform: uppercase;

  @include mobile() {
    @include montserrat-bold-32();
  }

  @media (max-width: 1036px) {
    @include montserrat-bold-32();
  }
}

.button {
  font-weight: 400;
  padding: 12px 55px;
  text-wrap: nowrap;
  width: 147px;

  @include mobile() {
    padding: 12px 44px;
  }

  @media (max-width: 1036px) {
    padding: 12px 44px;
    width: 105px;
  }
}

.subtitle {
  @include ubuntu-14();
  color: #d2bdad;
}

.badge {
  @include ubuntu-11();
  border-radius: 34px;
  color: #151727;
  height: max-content;
  margin-left: 3px;
  padding: 2px 10px;
}

.images {
  align-items: center;
  display: flex;
}

.dusty_rose {
  border: 2px solid #776c68;

  .title {
    background: linear-gradient(272deg, #958078 -3.85%, #f1dccc 59.4%, #b19681 100.65%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 65px #cdb5a2;
  }
}

.lemon_chiffon {
  border: 2px solid #e6c04e;

  .title {
    background: linear-gradient(272deg, #e8c251 -3.85%, #fae384 59.4%, #edc35b 100.65%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 65px #feec90;
  }
}

.peach {
  border: 2px solid #af8553;

  .title {
    background: linear-gradient(272deg, #b89054 -3.85%, #e7bb79 59.4%, #a87e4e 100.65%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 65px #ffcc7e;
  }
}

.silver {
  border: 2px solid #7d7d7d;

  .title {
    background: linear-gradient(272deg, #818181 -3.85%, #d9d9d9 59.4%, #ababab 100.65%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 65px #d1d1d1;
  }
}

.coral {
  border: 2px solid #7e2e45;

  .title {
    background: linear-gradient(260deg, #ab434f -10.43%, #e392aa 76.94%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 65px #ff7da1;
  }
}

.camel {
  border: 2px solid #8a6e4c;

  .title {
    background: linear-gradient(272deg, #7a5e3c -3.85%, #ccae8a 59.4%, #a88965 100.65%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 65px #c8aa86;
  }
}

.purple {
  border: 2px solid #842be1;

  .title {
    background: linear-gradient(261deg, #842be1 10.77%, #af7eff 89.93%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 65px #ae7cff;
  }
}

.bottom_content {
  align-items: center;
  animation: fadeInUp 1s forwards;
  display: flex;

  // justify-content: space-between;
  margin-top: auto;
}

.header_content {
  display: flex;
  justify-content: space-between;
}

.item.subscription {
  animation: 0;
  opacity: 1;
  padding: 14px 22px;

  .title {
    font-size: 22px;
    margin-top: 28px;
  }

  .subtitle {
    font-size: 12px;
  }

  .images {
    margin-top: 14px;
  }

  .button {
    margin-top: 20px;
    width: auto;
  }

  .bottom_content {
    animation: none;
  }
}

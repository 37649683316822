.block {
    align-items: flex-end;
    background-color: #252537;
    border-bottom-right-radius: 8px;
    display: flex;
    min-height: 48px;
    padding: 12px 16px;

    .textarea {
        color: var(--main-color);
        display: flex;
        flex: 1;
        height: 20px;
        margin: 0 15px;
        margin-top: 4px;
        resize: none;
    }
}

@import "app/styles/mixins/fonts.scss";

.btn {
    @include ubuntu-11();
    color: var(--main-color4);

    &:hover {
        color: var(--main-color);
    }
}

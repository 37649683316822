@import "../../../app/styles/mixins/fonts.scss";

.container {
  position: relative;
  width: 100%;
}

.button {
  @include ubuntu-14();
  background: #313140;
  border-radius: 8px;
  color: rgba(93, 93, 108, 1);
  padding: 12px 14px;
  width: 100%;
}

.dropdown {
  max-width: max-content;
  position: absolute;
  top: 45px;
  z-index: 100;
}

.container {
  background-color: rgba(37, 37, 55, 1);
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
  padding: 24px 31px;
}

.select {
  background-color: rgba(255, 255, 255, 0.05);
  width: 100%;
}

.input {
  span {
    pointer-events: none;
  }
}

.top_content {
  display: grid;
  gap: 8px;
  grid-template-columns: 2fr 130px 2fr;
  width: 100%;
}

.middle_contanet {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 120px) 2fr;
  width: 100%;
}

.bottom_content {
  display: grid;
  gap: 8px;
  grid-template-columns: 2fr repeat(2, 192px);
  width: 100%;
}

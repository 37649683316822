@import "app/styles/mixins/fonts.scss";
@import "app/styles/mixins/breakpoints.scss";

.date {
    @include ubuntu-14();
    color: #5d5d6c;
    margin-bottom: 13px;
    text-align: center;
    @include mobile() {
        font-size: 12px;
    }
}

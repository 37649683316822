@import "../../app/styles/mixins/fonts.scss";
@import "../../app/styles/mixins/breakpoints.scss";

.item_link {
  @include ubuntu-14();
  text-decoration: none;
}

.item {
  display: grid;
  gap: 15px;
  grid-template-columns: auto 1fr auto;
}

.active_icon {
  align-items: center;
  display: flex;
  height: max-content;

  svg {
    path {
      stroke: #feec90;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  list-style: none;
  margin: 0;
  min-width: 192px;
  padding: 0;

  @include mobile() {
    padding-bottom: 100px;
  }
}

.active_item_link {
  color: #feec90;
}

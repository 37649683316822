.button {
  box-shadow: 0 0 63px 0 #e6c04e75;
  margin-left: auto;
  margin-top: 25px;
  max-width: 200px;

  &:disabled {
    box-shadow: none;
    color: rgba(255, 255, 255, 0.05);

    &::before {
      background: rgba(255, 255, 255, 0.05);
    }
  }
}

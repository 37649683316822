@import "../../../app/styles/mixins/fonts.scss";
@import "../../../app/styles/mixins/breakpoints.scss";

.tabs {
  display: flex;
  flex-direction: column;
  gap: 35px;
  position: relative;
  width: 100%;
}

.list {
  align-items: center;
  display: flex;
  gap: 40px;
  margin: 0 auto;
  width: calc(100% - 100px);

  @media (max-width: 1036px) {
    gap: 0;
    justify-content: space-between;
  }

  // @include mobile() {
  //   gap: 0;
  //   justify-content: space-between;
  // }

  &::after {
    background-color: #42424f;
    border-radius: 8px;
    content: "";
    height: 3px;
    position: absolute;
    top: 25px;
    width: calc(100% - 100px);
  }
}

.button {
  @include ubuntu-14();
  background-color: transparent;
  border: none;
  color: #42424f;
  cursor: pointer;
  display: flex;
  padding: 0;
  position: relative;
  transition: transform 0.3s ease;
  transition: 0.3s;

  &.active_tab {
    color: #ffffff;
    transition: 0.3s;
  }

  &.hot {
    color: rgba(255, 95, 95, 1);
  }

  &::before {
    background-color: #ffffff;
    border-radius: 8px;
    content: "";
    height: 3px;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 25px;
    transition: 1s;
    width: 100%;
    z-index: 2;
  }

  &:not(.active_tab)::before {
    opacity: 0;
    transition: 0.3s;
  }

  @media (max-width: 1036px) {
    @include ubuntu-12();
    color: #42424f;
  }
}

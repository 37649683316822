@import "../../app/styles/mixins/fonts.scss";

.content {
  display: flex;
  gap: 16px;
}

.banner {
  align-items: center;
  background-color: rgba(37, 37, 55, 1);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  padding: 20px 48px 21px 49px;
}

.banner_left_content {
  display: flex;
  gap: 16px;
}

.button {
  gap: 14px;
  max-width: 192px ();
}

.title {
  @include ubuntu-medium-16();
}

.filters {
  background-color: rgba(37, 37, 55, 1);
  height: 250px;
  width: 100%;
}

.info_container {
  width: 100%;
}

.tabs {
  margin-top: 20px;
}

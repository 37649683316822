@import "../../../app/styles/mixins/fonts.scss";
@import "../../../app/styles/mixins/breakpoints.scss";
@import "../../../app/styles//mixins/utility.scss";

.list {
  display: grid;
  gap: 25px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);

  @include mobile() {
    grid-template-columns: 1fr;
  }

  @media (max-width: 1118px) {
    grid-template-columns: 1fr;
  }
}

.item {
  @include appearances();
}

.buy_catalog_button {
  @include ubuntu-16();
  align-items: center;
  background: rgba(37, 37, 55, 0.5);
  border-radius: 16px;
  color: #feec90;
  display: flex;
  gap: 15px;
  height: 100%;
  justify-content: center;
  padding: 96px 0;
  text-wrap: nowrap;
  width: 100%;

  @include mobile() {
    gap: 5px;
    padding: 24px 0;
  }

  @media (max-width: 1118px) {
    gap: 5px;
    height: auto;
    padding: 24px 0;
  }
}

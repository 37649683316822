.row {
  align-items: start;
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(8, 1fr);
}

.title {
  width: 110px;
}

.listIds {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.hidden {
  height: 0;
  line-height: 0;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  width: 0;
}

.image_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add_new_ids_button {
  background-color: #303041;
  border: none;
  border-radius: 8px;
  color: inherit;
  color: #ffffff;
  cursor: pointer;
  font: inherit;
  height: 34px;
  outline: none;
  padding: 3px;
  width: 70px;

  &:disabled {
    background: rgba(255, 255, 255, 0.05);
    color: #5d5d6c;
  }
}

.add_ids_button {
  background-color: #303041;
  border: none;
  border-radius: 8px;
  color: inherit;
  color: #ffffff;
  cursor: pointer;
  font: inherit;
  height: 34px;
  outline: none;
  padding: 3px;
  width: 40px;

  &:disabled {
    background: rgba(255, 255, 255, 0.05);
    color: #5d5d6c;
  }
}

.save_button {
  background: linear-gradient(270deg, #e6c04e 0.06%, #fbe587 53.49%, #ecc159 100.64%);
  border: none;
  border-radius: 8px;
  color: inherit;
  color: #000000;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 10px;

  &:disabled {
    background: rgba(255, 255, 255, 0.05);
    color: #5d5d6c;
  }
}

.save_container {
  align-items: center;
  display: flex;
  gap: 5px;
}

.input {
  background-color: #303041;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  outline: none;
  padding: 10px;
}

.image {
  height: 150px;
  width: 150px;
}

.container {
  width: 100%;
}

.header {
  align-items: center;
  display: grid;
  grid-template-columns: 140px 554px 137px 154px;
  min-height: 90px;
}

.item {
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
}

.button {
  max-width: 200px;
  padding: 25px;
}

.btn {
    path {
        fill: transparent;
        transition: 0.3s;
    }

    &:hover {
        cursor: pointer;

        path {
            fill: #e6c04e;
        }
    }
}

.itemIds {
  display: grid;
  gap: 15px;
  grid-template-columns: max-content 70px;
}

.input {
  background-color: #303041;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  color: #ffffff;
  outline: none;
  padding: 10px;
}

.add_new_ids_button {
  background-color: #303041;
  border: none;
  border-radius: 8px;
  color: inherit;
  color: #ffffff;
  cursor: pointer;
  font: inherit;
  height: 34px;
  outline: none;
  padding: 3px;
  width: 70px;

  &:disabled {
    background: rgba(255, 255, 255, 0.05);
    color: #5d5d6c;
  }
}

@import "app/styles/mixins/breakpoints.scss";

.box {
    background-color: #252537;
    display: flex;
    flex-direction: column;
    height: 680px;
    max-height: 680px;
    overflow: hidden auto;

    @include mobile() {
        background-color: transparent;
        height: calc(100dvh - 80px - 53px - 80px);
    }
}

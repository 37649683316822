@import "app/styles/mixins/fonts.scss";
@import "app/styles/mixins/breakpoints.scss";

.money-block {
    background-color: #252537;
    border-radius: 8px;

    // display: flex;
    margin-bottom: 8px;
    max-width: 50%;
    padding: 16px 24px;
    width: max-content;

    &.me {
        margin-left: auto;
    }

    &.manager {
    }

    .svg-block {
        align-items: center;
        background-color: #45444f;
        border-radius: 34px;
        display: flex;
        height: 64px;
        justify-content: center;
        width: 64px;
    }

    .info {
        display: flex;
        margin-left: 20px;

        .amount {
            @include ubuntu-medium-20();
        }

        .time {
            @include ubuntu-11();
            color: #7c7c92;
            margin-left: 32px;
        }
    }

    .request,
    .payed {
        margin-left: 20px;
        margin-top: 7px;
    }

    .request {
        @include ubuntu-14();
        color: #7c7c92;
    }

    .payed {
        @include ubuntu-medium-14();
        color: #feec90;
    }

    @include mobile() {
        max-width: 80%;
    }
}

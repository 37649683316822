@import "app/styles/mixins/breakpoints.scss";
@keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.box,
.comment-block {
    background-color: #252537;
    border-radius: 8px;
    font-size: 14px;
    margin-bottom: 8px;
    margin-left: auto;
    max-width: 80%;
    padding: 16px;
    width: 296px;

    .title-block {
        align-items: center;
        display: flex;

        .title {
            padding-left: 8px;
        }

        .time {
            color: #7c7c92;
            font-size: 11px;
            margin-left: auto;
        }
    }
}

.box {
    .folders-block {
        display: flex;
        gap: 8px;
        overflow-x: auto;
        padding-bottom: 8px;

        .girl-folder-btn {
            flex-shrink: 0;
            margin-top: 20px;
            padding: 8px 14px;
            width: max-content;

            &:hover {
                color: inherit;
            }

            &:not(.active) {
                color: #5d5d6c;
            }

            &.add-folder-btn {
                color: #5d5d6c;

                &:hover {
                    color: #ffffff;
                }
            }

            .icon {
                margin-left: 7px;
            }
        }
    }

    .fields-block {
        animation: show 1s backwards;
        display: none;
        gap: 8px;
        grid-template-columns: 1fr 1fr;
        padding-top: 8px;
        transition: 1s;

        &.active {
            animation: show 0.3s linear forwards;
            display: grid;
        }

        .field {
            background-color: rgba(255, 255, 255, 0.05);
            border-radius: 8px;
            padding: 18px 14px 6px;
            position: relative;

            .field-name {
                color: #5d5d6c;
                font-size: 10px;
                left: 14px;
                position: absolute;
                top: 6px;
            }
        }

        @include table() {
            grid-template-columns: 1fr;
        }
    }
}

.comment-block {
    .comment {
        margin-top: 14px;
    }
}

@import "../../../../app/styles/mixins/fonts.scss";

.add_button {
  display: flex;
  gap: 8px;

  svg g path {
    stroke: currentColor;
  }
}

.input {
  background: rgba(255, 255, 255, 0.05);
  margin-top: 5px;

  span {
    pointer-events: none;
  }
}

.image {
  position: relative;
}

.delete {
  position: absolute;
  right: 16px;
  top: 16px;
}

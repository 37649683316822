@import "../../../../app/styles/mixins/fonts.scss";

.item {
  align-items: center;
  background-color: #252537;
  border-radius: 8px;
  display: flex;
  gap: 25px;
  padding: 8px 22px;
}

.placeholder_text {
  @include ubuntu-11();
  color: rgba(107, 107, 125, 1);
}

.nickname {
  @include ubuntu-medium-14();
  margin-right: 53px;
}

.text {
  @include ubuntu-12();
}

.box {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  display: flex;
  gap: 15px;
  padding: 5px 16px 5px 12px;

  //   &:nth-child(1) {
  //     margin-right: 58px;
  //   }

  //   &:nth-child(2) {
  //     gap: 33px;
  //   }
}

.more {
  @include ubuntu-11();
  color: rgba(254, 236, 144, 1);
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 13px;
  margin-left: auto ();
  text-align: left;
}

@import "app/styles/mixins/breakpoints.scss";
@import "app/styles/mixins/utility.scss";

.widget {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);

    .block {
        @include appearances();
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > div:first-child {
            flex: 1;
        }
    }
    @include table() {
        grid-template-columns: repeat(3, 1fr);
    }
    @include vw1000() {
        grid-template-columns: repeat(2, 1fr);
    }
    @include medium() {
        grid-template-columns: 1fr;
    }
}

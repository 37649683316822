@import "app/styles/mixins/fonts.scss";
@import "app/styles/mixins/breakpoints.scss";

.message-box {
    align-items: flex-end;
    border-radius: 8px 8px 8px 0;
    display: flex;
    gap: 16px;
    margin-bottom: 8px;
    margin-left: 7px;
    max-width: 50%;
    padding: 12px 16px;
    position: relative;
    width: max-content;

    .message {
        @include ubuntu-14();
        word-break: break-all;
    }

    .time {
        @include ubuntu-11();
        color: #7c7c92;
    }

    &.me {
        background-color: #42424f;
        border-radius: 8px 8px 0;
        margin-left: auto;
        margin-right: 7px;

        &.last {
            &::before {
                background-color: inherit;
                bottom: 0;
                clip-path: polygon(0% 0%, 20% 50%, 30% 60%, 40% 70%, 100% 100%, 0% 100%);
                content: "";
                display: block;
                height: 14px;
                position: absolute;
                right: -7px;
                width: 7px;
            }
        }
    }

    &.manager {
        background-color: #252537;

        &.last {
            &::before {
                background-color: inherit;
                bottom: 0;
                clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 70% 60%, 80% 50%, 90% 40%);
                content: "";
                display: block;
                height: 14px;
                left: -7px;
                position: absolute;
                width: 7px;
            }
        }
    }
    @include mobile() {
        max-width: 80%;
    }
}

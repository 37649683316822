@import "app/styles/mixins/fonts.scss";

.table {
  padding: 16px;
  @include ubuntu-11();
  /* stylelint-disable-next-line order/properties-alphabetical-order */
  color: #5d5d6c;

  .inputs-block {
    display: flex;
    gap: 8px;
    margin-bottom: 20px;

    & > div {
      flex-grow: 1;
    }
  }

  .row {
    display: grid;
    grid-template-areas: "date action action action sum";
    grid-template-columns: 1fr 1fr 1fr 1fr auto;

    a {
      text-decoration: none;
      color: #e6c04e;
      white-space: nowrap;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .date {
      grid-area: date;
    }

    .action {
      grid-area: action;
    }

    .sum {
      grid-area: sum;
      text-align: right;

      &.up {
        color: #feec90;
      }

      &.down {
        color: #e6c04e;
      }
    }
  }
}
